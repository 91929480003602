// import * as moment from "moment";
import { format } from "date-fns";
import { ContestStatus } from "../types";

export function formatDate(d: string, withTime: boolean = true) {
  // return new Date(d).toDateString();
  // return moment(d).calendar()//.format('MMMM Do YYYY, h:mm:ss a');
  if (withTime) {
    return format(new Date(d), "M/d/yy h:mma");
  }
  return format(new Date(d), "M/d/yy");
}

export type AmericanOdds = "american";
export type FormatType = AmericanOdds;
export function formatOdds(n: number, format: FormatType = "american"): string {
  if (format === "american") {
    if (n > 2) {
      return `+${Math.round((n - 1) * 100)}`;
    } else {
      return `${Math.round(-100 / (n - 1))}`;
    }
  }
  throw Error("Invalid format passed in");
}

export function formatSpread(n: number): string {
  if (n > 0) return `+${n}`;
  return `${n}`;
}

export function getToWin(wager: number, odds: number): number {
  return wager * odds - wager;
}

const basePath = "/app";
// const basePath =
//   import.meta.env.VITE_BASE_ROUTE_PATH !== undefined
//     ? import.meta.env.VITE_BASE_ROUTE_PATH
//     : "";

export function getURL(url?: string): string {
  if (url !== undefined) {
    return basePath + url;
  }
  return basePath as string;
}

export function getStatus(start: Date, end: Date): ContestStatus {
  if (new Date(end) < new Date()) {
    return ContestStatus.PAST;
  } else if (new Date(start) < new Date()) {
    return ContestStatus.ACTIVE;
  }
  return ContestStatus.UPCOMING;
}
