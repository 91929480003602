<template>
  <div class="px-4 py-2 bg-white border rounded-2xl">
    <template v-if="contestName">
      <div class="text-xs text-gray-600 font-medium mb-1">
        {{ contestName }}
      </div>
    </template>
    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-col">
        <span class="font-bold">
          {{ betOverview.headline }}
        </span>
        <div class="text-xs text-gray-700">
          {{ betOverview.gameDescription }}
        </div>
      </div>
      <span class="w-6 h-6 rounded-lg text-center" :class="outcomeStyles">
        {{ outcome }}
      </span>
    </div>
    <div class="grid grid-cols-3 mt-2 border-t pt-2">
      <div
        class="flex flex-col items-center justify-center text-sm font-semibold"
      >
        <span>{{ formattedWager }}</span>
        <span class="text-xs text-gray-600">Wager</span>
      </div>
      <div
        class="flex flex-col items-center justify-center text-sm font-semibold"
      >
        <span>{{ formattedOdds }}</span>
        <span class="text-xs text-gray-600">Odds</span>
      </div>
      <div
        class="flex flex-col items-center justify-center text-sm font-semibold"
      >
        <span
          :class="[
            props.bet.outcome === 1
              ? 'text-green-600 font-bold'
              : props.bet.outcome === 0
              ? 'text-gray-600'
              : null,
          ]"
          >{{ formattedToWin }}</span
        >
        <span
          class="text-xs"
          :class="[
            props.bet.outcome === 1
              ? 'text-green-600 font-bold '
              : 'text-gray-600',
          ]"
          >{{ toWinLabel }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { formatOdds, formatSpread, getToWin } from "../../utils";

import { PlacedBet, PotentialBetType, Team } from "../../types";

const props = defineProps({
  bet: {
    type: Object as PropType<PlacedBet>,
  },
  contestName: {
    type: String,
  },
});

const outcome = computed(() => {
  if (props.bet.outcome === null) return;
  if (props.bet.outcome === 0) return "L";
  if (props.bet.outcome === 1) return "W";
  if (props.bet.outcome === -1) return "P";
});
const outcomeStyles = computed(() => {
  if (props.bet.outcome === null) return null;
  //   if (props.bet.outcome === null) return "bg-primary-200 text-primary-700";
  if (props.bet.outcome === 0) return "bg-red-400 text-white";
  if (props.bet.outcome === 1) return "bg-green-600 text-white";
  if (props.bet.outcome === -1) return "bg-primary-600 text-white";
});
const formattedOdds = computed(() => {
  return formatOdds(props.bet.odds);
});

const formattedWager = computed(() => {
  return "$" + props.bet.wager;
});
const toWinValue = computed(() => {
  return getToWin(props.bet.wager, props.bet.odds).toFixed(2);
});
const formattedToWin = computed(() => {
  if (props.bet.outcome === null) return "$" + toWinValue.value;
  if (props.bet.outcome === 0) return "$0.00";
  if (props.bet.outcome === 1) return "$" + toWinValue.value;
  return "$" + props.bet.wager;
});

const toWinLabel = computed(() => {
  if (props.bet.outcome === null) return "To Win";
  else if (props.bet.outcome === 0) return "Payout";
  return "Payout";
});

function getTeamText(t: Team): string {
  if (t.abbrev) return t.abbrev;
  return t.teamName;
}

const betOverview = computed(() => {
  let betType;
  let betDetails;
  if (props.bet.moneyline.length > 0) {
    betDetails = props.bet.moneyline[0];
    betType = betDetails.onHome
      ? PotentialBetType.HOME_ML
      : PotentialBetType.AWAY_ML;
  } else if (props.bet.spread.length > 0) {
    betDetails = props.bet.spread[0];
    betType = betDetails.onHome
      ? PotentialBetType.HOME_SPREAD
      : PotentialBetType.AWAY_SPREAD;
  } else if (props.bet.total.length > 0) {
    betDetails = props.bet.total[0];
    betType = betDetails.over
      ? PotentialBetType.TOTAL_OVER
      : PotentialBetType.TOTAL_UNDER;
  }

  let headline: string = "headline";
  if (betType === PotentialBetType.HOME_SPREAD) {
    headline = `${getTeamText(betDetails.game.homeTeam)} ${formatSpread(
      betDetails.homeSpread
    )}`;
  } else if (betType === PotentialBetType.AWAY_SPREAD) {
    headline = `${getTeamText(betDetails.game.awayTeam)} ${formatSpread(
      betDetails.homeSpread * -1
    )}`;
  } else if (betType === PotentialBetType.HOME_ML) {
    headline = `${getTeamText(betDetails.game.homeTeam)} ML`;
  } else if (betType === PotentialBetType.AWAY_ML) {
    headline = `${getTeamText(betDetails.game.awayTeam)} ML`;
  } else if (betType === PotentialBetType.TOTAL_OVER) {
    headline = `O${betDetails.total}`;
  } else if (betType === PotentialBetType.TOTAL_UNDER) {
    headline = `U${betDetails.total}`;
  }
  return {
    headline,
    gameDescription: `${getTeamText(betDetails.game.homeTeam)} vs ${getTeamText(
      betDetails.game.awayTeam
    )}`,
  };
});
</script>
