<script lang="ts">
import { PropType } from "vue";
import { NotificationLocation } from "./notify";

export default {
  provide() {
    return {
      ["context"]: { group: this.group, position: this.position },
    };
  },
  props: {
    group: {
      type: String,
      default: "",
    },
    position: {
      type: String as PropType<NotificationLocation>,
      default: NotificationLocation.bottom,
      // validator(value) {
      //   return ["top", "bottom"].includes(value);
      // },
    },
  },
  render() {
    return this.$slots.default({
      group: this.group,
    });
  },
};
</script>
