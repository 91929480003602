import { supabase } from "../supa";

export function getUserPerformance(contestId: string, userId?: string) {
  let query = supabase
    .from("contest_leaderboard")
    // , user:user_id (id, firstName:first_name, lastName:last_name)
    .select(
      `contestId:contest_id, userId:user_id, firstName:first_name, lastName:last_name
    , balance, isAdmin:is_admin, isBoughtIn:is_bought_in, joined
    , totalAlive:total_alive, numAlive:num_alive, numWon:num_won, numTotal:num_total
    , winPct:win_pct, profit, profitPct:profit_pct, roi
    `
    )
    .filter("contest_id", "eq", contestId);

  if (userId) {
    query.filter("user_id", "eq", userId);
  }
  return query;
}
