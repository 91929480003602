import { createApp } from "vue";
import App from "./App.vue";
import "./styles/index.css";
import { getRoutes } from "./router";
const pinia = createPinia();

import { createRouter, createWebHistory } from "vue-router";
// import { createMockServer } from "../mock/server";
import { createPinia } from "pinia";
// createMockServer();
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: getRoutes(), // short for `routes: routes`
});
const app = createApp(App);
app.use(router);
import Notifications from "./components/notifications";
app.use(pinia);
app.use(Notifications);
app.mount("#app");
