<template>
  <div class="h-full flex flex-col gap-4 items-center py-6 px-2">
    <template v-if="loading"> <Loading /> </template>
    <template v-else>
      <div>
        <!-- <img
          class="w-24 rounded-full"
          :src="profile.profilePhoto"
          alt="Couldnt"
        /> -->
        <div class="mt-2 text-lg font-semibold">
          {{ profile.firstName }} {{ profile.lastName }}
        </div>
      </div>
      <div v-if="profile.isAdmin" class="flex flex-col gap-4 w-full">
        <Btn @click="updateNBA">Update NBA</Btn>
        <Btn @click="updateMLB">Update MLB</Btn>
      </div>
      <div>
        <Btn @click="signOut">Sign Out</Btn>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import Btn from "../components/lib/inputs/Btn.vue";
import { supabase } from "../supa";

import { ref } from "vue";
import Loading from "../components/lib/inputs/Loading.vue";
import { useAuthStore, UserProfile } from "../state/auth";
import { NotificationType, notify } from "../components/notifications";
let profile = ref<UserProfile>();

const loading = ref(true);

const authStore = useAuthStore();
async function fetchProfile() {
  // const resp = await fetch("/api/profile");
  // const json = await resp.json();
  // console.log({ json });
  // const { data, error } = await supabase
  //   .from("user_profile")
  //   .select()
  //   .filter("id", "eq", authStore.user.id)
  //   .single();
  // if (error) {
  //   notify(
  //     {
  //       type: NotificationType.ERROR,
  //       text: "Could not join contest!",
  //     },
  //     4000
  //   );
  //   return;
  // }

  // const up: UserProfile = {
  //   firstName: data.first_name,
  //   lastName: data.last_name,
  //   fullName: `${data.first_name} ${data.last_name}`,
  //   profilePhoto: data.profile_photo,
  // };

  // profile.value = up;

  profile.value = authStore.profile;
  loading.value = false;
}
fetchProfile();

async function signOut() {
  const { error } = await supabase.auth.signOut();
}

async function updateNBA() {
  const resp = await fetch("https://admin.topsharp.app/api/admin/getOdds/12");
  if (resp.status !== 200) {
    notify(
      {
        type: NotificationType.ERROR,
        text: `Something went wrong`,
      },
      4000
    );
    return;
  }
  const json = await resp.json();
  if (
    json.upsertGameError != null ||
    json.moneylineInsertError != null ||
    json.spreadInsertError != null ||
    json.totalInsertError != null
  ) {
    notify(
      {
        type: NotificationType.ERROR,
        text: `Code 200 but errors`,
      },
      4000
    );
    console.error({ json });
    return;
  }

  notify({
    type: NotificationType.SUCCESS,
    text: "NBA updated!",
  });
}
async function updateMLB() {
  const resp = await fetch("https://admin.topsharp.app/api/admin/getOdds/mlb");
  if (resp.status !== 200) {
    notify(
      {
        type: NotificationType.ERROR,
        text: `Something went wrong`,
      },
      4000
    );
    return;
  }
  const json = await resp.json();
  if (
    json.upsertGameError != null ||
    json.moneylineInsertError != null ||
    json.spreadInsertError != null ||
    json.totalInsertError != null
  ) {
    notify(
      {
        type: NotificationType.ERROR,
        text: `Code 200 but errors`,
      },
      4000
    );
    console.error({ json });
    return;
  }

  notify({
    type: NotificationType.SUCCESS,
    text: "MLB updated!",
  });
}
</script>
