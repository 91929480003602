<template>
  <div class="h-full">
    <template v-if="loading"> <Loading /> </template>
    <template v-else>
      <div class="px-2 py-2">
        <Btn type="text" size="xs" @click="$router.go(-1)" class="mb-4">
          Back to Leaderboard
        </Btn>
        <div class="mt-2">
          <div class="text-3xl font-bold stickyy top-0">Placed Bets</div>
          <template v-if="placedBets && placedBets.length">
            <div class="flex flex-col px-2 py-2 gap-2 pb-16">
              <template v-for="b in placedBets" :key="b.id">
                <PlacedBetComponent :bet="b" />
              </template>
            </div>
          </template>
          <template v-else>
            User hasn't placed any bets on games that have already started
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import PlacedBetComponent from "../../components/contests/PlacedBet.vue";
import { ContestUserDetail, PlacedBet } from "../../types";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import Btn from "../../components/lib/inputs/Btn.vue";
import Loading from "../../components/lib/inputs/Loading.vue";
import { useAuthStore } from "../../state/auth";
import { supabase } from "../../supa";
import { NotificationType, notify } from "../../components/notifications";
import { getPlacedBets } from "../../query/getPlacedBets";
const route = useRoute();
let userDetail = ref<string>(null);
let loading = ref<boolean>(true);

let placedBets = ref<PlacedBet[]>([]);
const authStore = useAuthStore();
async function getUserBets() {
  loading.value = true;

  const { data, error } = await getPlacedBets(contestId.value, userId.value);

  // .lt('total_bet:game_id:start_date', new Date().toISOString())
  const now = new Date().toISOString();
  let filteredData = data;
  if (authStore.user.id !== userId.value) {
    filteredData = data.filter((b) => {
      if (b.moneyline.length) {
        return b.moneyline[0].game.startDate < now;
      }
      if (b.spread.length) {
        return b.spread[0].game.startDate < now;
      }
      if (b.total.length) {
        return b.total[0].game.startDate < now;
      }
    });
  }

  // console.log("Get placed bets: ", { data, error });
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    loading.value = false;
    return;
  }
  placedBets.value = filteredData.sort((a, b) => {
    if (a.outcome === null) {
      return -1;
    }
    if (b.outcome === null) {
      return 1;
    }
    if (new Date(b.placedAt) > new Date(a.placedAt)) {
      return 1;
    } else {
      return -1;
    }
  });
  loading.value = false;
}

async function getContestUserDetail() {
  // const resp = await fetch(
  //   `/mock-api/contests/contestUserDetail/${route.params.userId}`
  // );
  // // console.log(resp);
  // const json = await resp.json();
  userDetail.value = "phil mock";
  loading.value = false;
}

let contestId = ref<string>(undefined);
let userId = ref<string>(undefined);

onMounted(() => {
  contestId.value = route.params.contestId as string;
  userId.value = route.params.userId as string;
  getUserBets();
});
</script>
