import { User } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { NotificationType, notify } from "../components/notifications";
import { supabase } from "../supa";

type AuthUser = User;

export type UserProfile = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  isComplete: boolean;
  isAdmin: boolean;
};

export const useAuthStore = defineStore("auth", {
  state: (): {
    user: AuthUser | null;
    loading: boolean;
    profile: UserProfile | null;
  } => {
    return {
      user: null,
      loading: false,
      profile: null,
    };
  },
  actions: {
    async getCurrentProfile(uid) {
      const { data, error } = await supabase
        .from("user_profile")
        .select()
        .eq("id", uid)
        .single();
      if (error) {
        notify(
          {
            type: NotificationType.ERROR,
            text: `Unable to fetch user profile`,
          },
          4000
        );
        return null;
      }
      const profile = {
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        isComplete: data.is_complete,
        isAdmin: data.admin,
      };

      this.profile = profile;
      return profile;
    },
  },
});
