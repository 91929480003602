import Lobby from "../views/lobby/Lobby.vue";
import LobbyIndex from "../views/lobby/Index.vue";
import MyContests from "../views/contests/MyContests.vue";
import Profile from "../views/Profile.vue";
import ContestIndex from "../views/contests/Index.vue";
import ContestDetail from "../views/contests/ContestDetail.vue";
import ContestParticipants from "../views/contests/ContestParticipants.vue";
import ContestUserDetail from "../views/contests/ContestUserDetail.vue";
import ContestPlaceBets from "../views/contests/PlaceBets.vue";
import Components from "../views/Components.vue";
import PageNotFound from "../views/404.vue";
import Auth from "../views/Auth.vue";
import MyBets from "../views/MyBets.vue";
import FinishProfile from "../views/FinishProfile.vue";
import AuthRedirect from "../views/AuthRedirect.vue";
import InternalApp from "../views/InternalApp.vue";
import { useAuthStore } from "../state/auth";
import { getURL } from "../utils";
import { watch } from "vue";
import { ContestStatus } from "../types";
import { RouteRecordRaw } from "vue-router";

export const authGuard = (to, from, next) => {
  const store = useAuthStore();
  // when done loading, check auth
  const fn = (clearWatchers) => {
    if (clearWatchers) {
      watchLoading();
      watchProfile();
    }
    // if authed, nav
    if (store.user && store.profile.isComplete) {
      return next();
    }
    if (store.user && !store.profile.isComplete) {
      return next("/finish-profile");
    }
    // return to login
    return next("/login?to=" + to.fullPath);
  };

  // if auth already loaded, and profile complete
  if (store.loading === false && store.profile) {
    return fn(false);
  }

  // if auth hasnt loaded, wait until it does and then check auth
  const watchLoading = watch(
    () => store.loading,
    (isLoading) => {
      if (!isLoading) {
        fn(true);
      }
    }
  );
  const watchProfile = watch(
    () => store.profile,
    (profile) => {
      if (profile) {
        fn(true);
      }
    }
  );
};

// inverse of auth guard
export const unAuthGuard = (to, from, next) => {
  const store = useAuthStore();
  const fn = () => {
    if (!store.user) {
      return next();
    }

    if (to.query.to) {
      return next(to.query.to);
    }
    return next(getURL(`/contests?t=${ContestStatus.ACTIVE}`));
  };
  if (store.loading === false) {
    return fn();
  }
  watch(
    () => store.loading,
    (isLoading) => {
      if (!isLoading) {
        fn();
      }
    }
  );
};

// inverse of auth guard
export const incompleteProfileGuard = (to, from, next) => {
  const store = useAuthStore();
  const fn = (clearWatchers) => {
    if (clearWatchers) {
      watchLoading();
      watchProfile();
    }
    if (!store.user) {
      return next(getURL("/login?to=" + to.fullPath));
    }
    if (store.user && !store.profile.isComplete) {
      return next();
    }

    // if (to.query.to) {
    //   return next(to.query.to);
    // }
    return next(getURL(`/contests?t=${ContestStatus.ACTIVE}`));
  };
  if (store.loading === false) {
    return fn(false);
  }
  const watchLoading = watch(
    () => store.loading,
    (isLoading) => {
      if (!isLoading) {
        fn(true);
      }
    }
  );
  const watchProfile = watch(
    () => store.profile,
    (profile) => {
      if (profile) {
        fn(true);
      }
    }
  );
};
const getRoutes = (): RouteRecordRaw[] => [
  // main app
  {
    path: getURL(),
    component: InternalApp,
    beforeEnter: authGuard,
    // beforeUpdate: routerGuard,
    children: [
      {
        name: "lobby",
        path: `lobby`,
        component: Lobby,
        children: [
          {
            path: "",
            component: LobbyIndex,
          },
          {
            path: ":contestId",
            component: ContestDetail,
            props: {
              initialJoined: false,
            },
          },
          { path: ":contestId/leaderboard", component: ContestParticipants },
        ],
      },
      {
        name: "contests",
        path: `contests`,
        component: MyContests,
        children: [
          { path: "", component: ContestIndex },
          {
            path: ":contestId",
            component: ContestDetail,
            props: {
              initialJoined: true,
            },
          },
          { path: ":contestId/leaderboard", component: ContestParticipants },
          { path: ":contestId/:userId", component: ContestUserDetail },
          { path: ":contestId/placeBets", component: ContestPlaceBets },
        ],
      },
      { path: `profile`, component: Profile },
      { path: `components`, component: Components },
      {
        path: "my-bets",
        component: MyBets,
      },
      // default is contests page
      { path: ``, redirect: `${getURL()}/contests?t=2` },
    ],
  },
  { path: `/login`, component: Auth, beforeEnter: unAuthGuard },
  {
    path: "/finish-profile",
    component: FinishProfile,
    beforeEnter: incompleteProfileGuard,
  },
  { path: `/auth-direct`, component: AuthRedirect, beforeEnter: unAuthGuard },
  { path: `/`, redirect: `${getURL()}/contests?t=2` },
  { path: `/404`, component: PageNotFound },
  // { path: "/:catchAll(.*)", redirect: `${getURL()}/404` },
  { path: ``, redirect: `/login` },
  { path: "/:pathMatch(.*)*", redirect: "/404" },
];
export { getRoutes };
