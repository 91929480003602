<template>
  <div class="relative">
    <span class="absolute left-2 py-1 font-medium text-gray-400">$</span>
    <input
      @focus="$emit('focus')"
      type="text"
      ref="inputRef"
      class="px-2 py-1 shadow-sm text-right focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-lg"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, PropType, watch } from "vue"; // Vue 2: import { watch } from '@vue/composition-api'
import { CurrencyInputOptions, useCurrencyInput } from "vue-currency-input";
const props = defineProps({
  modelValue: Number, // Vue 2: value
  options: {
    type: Object as PropType<CurrencyInputOptions>,
    default: () => ({
      currency: "USD",
      currencyDisplay: "hidden",
      valueRange: {
        min: 0,
      },
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      autoSign: false,
      useGrouping: true,
      accountingSign: false,
    }),
  },
});
const { inputRef, setOptions, setValue } = useCurrencyInput(props.options);

watch(
  () => props.modelValue, // Vue 2: props.value
  (value) => {
    setValue(value);
  }
);

watch(
  () => props.options,
  (options) => {
    setOptions(options);
  }
);
</script>
