<template>
  <div class="h-full relative">
    <template v-if="loading"> <Loading /> </template>
    <template v-else>
      <!-- hack to prevent bg scroll when drawer is open -->
      <div
        class="p-2 pb-24"
        :class="{ 'fixed max-h-screen overflow-hidden w-full': drawerOpen }"
      >
        <Btn type="text" size="xs" @click="$router.go(-1)" class="mb-4">
          Back
        </Btn>
        <div class="flex flex-col gap-2">
          <template v-if="games && games.length">
            <template v-for="game in games" :key="game.id">
              <GamePotentialBetCard :game="game" />
            </template>
          </template>
          <template v-else> No Games </template>
        </div>
      </div>
      <div
        class="absolute inset-0 bg-gray-500 opacity-10 h-screen overflow-hidden"
        v-if="drawerOpen"
        @click="toggleDrawer"
      ></div>
      <div
        class="fixed bottom-14 left-0 right-0 bg-gray-50 shadow-md transition-height duration-700 ease-in-out overflow-scroll"
        :class="[drawerOpen ? 'h-drawer' : 'h-10']"
      >
        <Tabs
          dense
          :modelValue="betDrawerSelection"
          @update:modelValue="handleClick"
          :options="betDrawerOptions"
          class="sticky top-0"
        />
        <template v-if="betDrawerSelection === 0">
          <div class="p-2 flex flex-col gap-2">
            <div class="text-sm text-center text-gray-600">
              Contest Balance:
              <template v-if="balance"> ${{ balance }} </template>
              <template v-else> -- </template>
            </div>
            <template v-if="Object.keys(placeBetsStore.betSlip).length">
              <!-- {{ placeBetsStore.betSlip }} -->
              <template
                v-for="bet in Object.values(placeBetsStore.betSlip)"
                :key="`${bet.gameId}_${bet.type}`"
              >
                <BetSlipBet
                  :contestId="contestId"
                  :bet="bet"
                  :game="getGameBet(bet)"
                  @placed-bet="placedBet"
                />
                <!-- TODO: remove function call here... should to somehow make this persistent -->
                <!-- :betDescription="getBetOverviewForBetSlip(bet)"
                  :betDetails="getBetDetailsForBetSlip(bet)" -->
              </template>
            </template>
            <template v-else>
              <Btn type="text" @click="toggleDrawer">Add bets to bet slip</Btn>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="p-2 flex flex-col gap-2">
            <template v-if="placeBetsStore.placedBets.length">
              <template
                v-for="bet in placeBetsStore.placedBets"
                :key="`${bet.id}`"
              >
                <PlacedBet :bet="bet" />
              </template>
            </template>
            <template v-else>
              <!-- <Btn type="text" @click="handleBDSelect('betSlip')">Place bets</Btn> -->
              place bets
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import {
  GamePotentialBet,
  PotentialBetType,
  PotentialMoneylineBet,
  PotentialSpreadBet,
  PotentialTotalBet,
  Team,
} from "../../types";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import Btn from "../../components/lib/inputs/Btn.vue";

import GamePotentialBetCard from "../../components/contests/GamePotentialBets.vue";
import { BetSlipBetOverview, BSB, usePlaceBetStore } from "../../state/contest";
import BetSlipBet from "../../components/contests/BetSlipBet.vue";
import { formatSpread } from "../../utils";
import Loading from "../../components/lib/inputs/Loading.vue";
import PlacedBet from "../../components/contests/PlacedBet.vue";
import Tabs from "../../components/lib/inputs/Tabs.vue";
import { NotificationType, notify } from "../../components/notifications";
import { supabase } from "../../supa";
import { useAuthStore } from "../../state/auth";
import { getPlacedBets } from "../../query/getPlacedBets";
// import Dinero from '../../../node_modules/dinero.js'

const route = useRoute();
let games = ref<GamePotentialBet[]>(null);
let loadingGames = ref<boolean>(true);
let loadingBets = ref<boolean>(true);
let balance = ref<number | null>(null);

async function getContestgames() {
  loadingGames.value = true;
  const { data: sports, error: sportsError } = await supabase
    .from("contest_sport")
    .select()
    .eq("contest_id", contestId.value);
  if (sportsError) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    return;
  }

  const sportIds = sports.map((s) => s.sport_id);
  const { data, error } = await supabase
    .from("game")
    .select(
      `
  id, start_date, home_score, away_score, is_complete,
  sport:sport_id (id, name),
  home_team:home_team_id (id, abbrev, team_name),
  away_team:away_team_id (id, abbrev, team_name),
  spread:curr_spread_bet (id, home_odds, away_odds, home_spread, book_id),
  moneyline:curr_moneyline_bet (id, home_odds, away_odds, book_id),
  total:curr_total_bet (id, over_odds, under_odds, total, book_id)
  `
    )
    .filter("start_date", "gt", new Date().toISOString())
    .filter("sport_id", "in", `(${sportIds})`);

  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    return;
  }
  const availableGames: GamePotentialBet[] = [];

  for (let g of data) {
    const game: GamePotentialBet = {
      id: g.id,
      startDate: g.start_date,
      homeTeam: {
        id: g.home_team.id,
        abbrev: g.home_team.abbrev,
        teamName: g.home_team.team_name,
      },
      awayTeam: {
        id: g.away_team.id,
        abbrev: g.away_team.abbrev,
        teamName: g.away_team.team_name,
      },
      sport: {
        id: g.sport.id,
        name: g.sport.name,
      },
    };
    if (g.spread) {
      game.spread = {
        id: g.spread.id,
        homeOdds: g.spread.home_odds,
        awayOdds: g.spread.away_odds,
        homeSpread: g.spread.home_spread,
        bookId: g.spread.book_id,
      };
    }
    if (g.moneyline) {
      game.moneyline = {
        id: g.moneyline.id,
        homeOdds: g.moneyline.home_odds,
        awayOdds: g.moneyline.away_odds,
        bookId: g.moneyline.book_id,
      };
    }
    if (g.total) {
      game.total = {
        id: g.total.id,
        overOdds: g.total.over_odds,
        underOdds: g.total.under_odds,
        total: g.total.total,
        bookId: g.total.book_id,
      };
    }
    availableGames.push(game);
  }

  games.value = availableGames.sort((a, b) => {
    return new Date(a.startDate) > new Date(b.startDate) ? 1 : -1;
  });

  placeBetsStore.checkValidity(games.value);

  loadingGames.value = false;
}

const authStore = useAuthStore();
async function getUserBets() {
  loadingBets.value = true;

  const { data, error } = await getPlacedBets(
    contestId.value,
    authStore.user.id
  ).is("outcome", null);

  // console.log("Get placed bets: ", { data, error });
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    loadingBets.value = false;
    return;
  }
  placeBetsStore.placedBets = data;
  loadingBets.value = false;
}

async function getContestBalance() {
  const { data, error } = await supabase
    .from("contest_user")
    .select()
    .eq("contest_id", contestId.value)
    .eq("user_id", authStore.user.id)
    .single();
  console.log({ data, error }, "+++++++++++");
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    return;
  }
  balance.value = data.balance;
}

let contestId = ref<string>(undefined);

onMounted(() => {
  contestId.value = route.params.contestId as string;
  getContestgames();
  getUserBets();
  getContestBalance();
});

function placedBet() {
  getUserBets();
  getContestBalance();
}

const loading = computed(() => loadingGames.value || loadingBets.value);

const drawerOpen = ref<boolean>(false);

const placeBetsStore = usePlaceBetStore();

const betDrawerSelection = ref<0 | 1>(0);
const betDrawerOptions = computed(() => [
  {
    value: 0,
    label:
      Object.keys(placeBetsStore.betSlip).length > 0
        ? `Bet Slip (${Object.keys(placeBetsStore.betSlip).length})`
        : "Bet Slip",
  },
  { value: 1, label: "Placed" },
]);

function toggleDrawer() {
  drawerOpen.value = !drawerOpen.value;
}

function getGameBet(betOverview: BSB): GamePotentialBet {
  const game = games.value.find((g) => g.id === betOverview.gameId);
  if (!game) {
    console.warn(`Could not find game with id ${betOverview.gameId}`);
    return;
  }
  return game;
}

function handleClick(v) {
  // if not open, set new value and open
  if (drawerOpen.value === false) {
    betDrawerSelection.value = v;
    toggleDrawer();
  }
  // if same value, close drawer
  else if (v === betDrawerSelection.value) {
    toggleDrawer();
  }
  // toggling back and forth while open
  else {
    betDrawerSelection.value = v;
  }
}
</script>
