<template>
  <div class="p-4 h-full flex flex-col">
    <div class="text-lg font-bold">My Profile Info</div>
    <div class="flex flex-col gap-2 mt-4 flex-grow">
      <VInput
        label="First Name"
        placeholder="--"
        v-model="profileData.firstName"
        :isError="profileErrors.firstName"
        :errorMessage="profileErrors.firstName"
      />
      <VInput
        label="Last Name"
        placeholder="--"
        v-model="profileData.lastName"
        :isError="profileErrors.lastName"
        :errorMessage="profileErrors.lastName"
      />
      <!-- <VInput label="Username" placeholder="--" v-model="profileData.firstName" /> -->
      <VButton class="mt-2" @click="updateProfile"> Update Profile </VButton>
    </div>
    <VButton class="" type="text" @click="signOut"> Sign Out </VButton>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import VInput from "../components/lib/inputs/Input.vue";
import VButton from "../components/lib/inputs/Btn.vue";
import { supabase } from "../supa";
import { useAuthStore } from "../state/auth";
import { NotificationType, notify } from "../components/notifications";
import { useRouter } from "vue-router";
import { getURL } from "../utils";
const router = useRouter();
const profileData = reactive({
  firstName: null,
  lastName: null,
  userName: null,
});
const profileErrors = reactive({
  firstName: null,
  lastName: null,
  userName: null,
});
const authStore = useAuthStore();
async function updateProfile() {
  if (!profileData.firstName || profileData.firstName.length === 0) {
    profileErrors.firstName = "Required";
  } else {
    profileErrors.firstName = null;
  }
  if (!profileData.lastName || profileData.lastName.length === 0) {
    profileErrors.lastName = "Required";
  } else {
    profileErrors.lastName = null;
  }

  if (profileErrors.firstName || profileErrors.lastName) {
    return;
  }

  const { data, error } = await supabase
    .from("user_profile")
    .update({
      first_name: profileData.firstName,
      last_name: profileData.lastName,
      is_complete: true,
    })
    .match({ id: authStore.user.id });
  // PHILTODO: check for error in this update
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Failed to update profile. Try again",
      },
      4000
    );
    return;
  }
  await authStore.getCurrentProfile(authStore.user.id);
  router.push(getURL("/contests"));
}
async function signOut() {
  const { error } = await supabase.auth.signOut();
}
</script>
