<template>
  <div
    class="grid gap-1 bg-primary-300 font-medium w-full"
    :class="[numColumnsClass, dense ? 'h-10 p-1' : 'h-12 p-1.5']"
  >
    <template v-for="opt in options" :key="opt.value">
      <div
        class="flex items-center justify-center h-full rounded-xl px-2 cursor-pointer"
        :class="[
          modelValue === opt.value
            ? 'text-white bg-primary-600'
            : 'text-primary-700',
        ]"
        @click="handleClick(opt.value)"
      >
        {{ opt.label }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, Prop, PropType } from "vue";

const props = defineProps({
  modelValue: {
    type: Number,
  },
  options: {
    type: Array as PropType<{ value: number; label: string }[]>,
    required: true,
  },
  dense: {
    type: Boolean,
    default: false,
  },
});

const numColumnsClass = computed(() => {
  const num = props.options.length;
  switch (num) {
    case 2:
      return "grid-cols-2";
    case 3:
      return "grid-cols-3";
    case 4:
      return "grid-cols-4";
  }
});

const emit = defineEmits(["update:modelValue"]);
function handleClick(v) {
  emit("update:modelValue", v);
}
</script>
