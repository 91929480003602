<template>
  <!-- shadow-md -->
  <div className="px-4 py-2 bg-white rounded-2xl  flex flex-col border">
    <div
      className="text-xs font-medium text-gray-500 flex flex-row justify-between"
    >
      <div className="">{{ formatDate(game.startDate) }}</div>
      <div
        className="w-48 sm:w-56 grid grid-cols-3 gap-1 text-xs text-center font-medium h-full"
      >
        <div>Spread</div>
        <div>ML</div>
        <div>Total</div>
      </div>
    </div>
    <div className="flex flex-col gap-1 relative flex-grow mt-1">
      <div
        className="absolute h-fulll top-1/2 -translate-y-1/2 text-xs font-bold text-gray-500"
      >
        vs
      </div>
      <!-- home -->
      <div
        className="flex flex-row justify-between items-center font-semibold h-full "
      >
        <div className="block sm:hidden text-base">
          {{
            game.homeTeam.abbrev ? game.homeTeam.abbrev : game.homeTeam.teamName
          }}
          <!-- {{ game.homeTeam.teamName }} -->
        </div>
        <div className="hidden sm:block text-base">
          {{ game.homeTeam.teamName }}
        </div>
        <div
          className="w-48 sm:w-56 grid grid-cols-3 gap-1 text-xs text-center font-semibold h-full"
        >
          <BetCell
            :ptsValue="game.spread ? game.spread.homeSpread : null"
            :oddsValue="game.spread ? game.spread.homeOdds : null"
            :type="PotentialBetType.HOME_SPREAD"
            @click="
              game.spread ? toggleBet(PotentialBetType.HOME_SPREAD) : null
            "
            :active="
              placeBetsStore.betSlip[
                `${game.id}_${PotentialBetType.HOME_SPREAD}`
              ]
                ? true
                : false
            "
          />
          <BetCell
            :oddsValue="game.moneyline ? game.moneyline.homeOdds : null"
            :type="PotentialBetType.HOME_ML"
            @click="game.moneyline ? toggleBet(PotentialBetType.HOME_ML) : null"
            :active="
              placeBetsStore.betSlip[`${game.id}_${PotentialBetType.HOME_ML}`]
                ? true
                : false
            "
          />
          <BetCell
            :ptsValue="game.total ? game.total.total : null"
            :oddsValue="game.total ? game.total.overOdds : null"
            :type="PotentialBetType.TOTAL_OVER"
            @click="game.total ? toggleBet(PotentialBetType.TOTAL_OVER) : null"
            :active="
              placeBetsStore.betSlip[
                `${game.id}_${PotentialBetType.TOTAL_OVER}`
              ]
                ? true
                : false
            "
          />
        </div>
      </div>
      <!-- away -->
      <div
        className="flex flex-row justify-between items-center font-semibold h-full "
      >
        <div className="block sm:hidden text-base">
          <!-- {{ game.awayTeam.abbrev }} -->
          {{
            game.awayTeam.abbrev ? game.awayTeam.abbrev : game.awayTeam.teamName
          }}
        </div>
        <div className="hidden sm:block text-base">
          {{ game.awayTeam.teamName }}
        </div>
        <div
          className="w-48 sm:w-56 grid grid-cols-3 gap-1 text-xs text-center font-semibold h-full"
        >
          <BetCell
            :ptsValue="game.spread ? game.spread.homeSpread : null"
            :oddsValue="game.spread ? game.spread.awayOdds : null"
            :type="PotentialBetType.AWAY_SPREAD"
            @click="
              game.spread ? toggleBet(PotentialBetType.AWAY_SPREAD) : null
            "
            :active="
              placeBetsStore.betSlip[
                `${game.id}_${PotentialBetType.AWAY_SPREAD}`
              ]
                ? true
                : false
            "
          />
          <BetCell
            :oddsValue="game.moneyline ? game.moneyline.awayOdds : null"
            :type="PotentialBetType.AWAY_ML"
            @click="game.moneyline ? toggleBet(PotentialBetType.AWAY_ML) : null"
            :active="
              placeBetsStore.betSlip[`${game.id}_${PotentialBetType.AWAY_ML}`]
                ? true
                : false
            "
          />
          <BetCell
            :ptsValue="game.total ? game.total.total : null"
            :oddsValue="game.total ? game.total.underOdds : null"
            :type="PotentialBetType.TOTAL_UNDER"
            @click="game.total ? toggleBet(PotentialBetType.TOTAL_UNDER) : null"
            :active="
              placeBetsStore.betSlip[
                `${game.id}_${PotentialBetType.TOTAL_UNDER}`
              ]
                ? true
                : false
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PropType } from "vue";
import { usePlaceBetStore } from "../../state/contest";
import { GamePotentialBet, PotentialBetType } from "../../types";
import { formatDate } from "../../utils";
import BetCell from "./BetCell.vue";

const props = defineProps({
  game: {
    type: Object as PropType<GamePotentialBet>,
    required: true,
  },
});

const placeBetsStore = usePlaceBetStore();

// TODO: add bet details here for proper display
// currently pointing back to games array to ensure that odds are always up to date with fresh game fetch
function toggleBet(type: PotentialBetType) {
  placeBetsStore.toggleBet(props.game.id, type);
}
</script>
