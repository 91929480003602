<template>
  <div class="px-4 py-2 bg-white border rounded-2xl">
    <div class="flex flex-row gap-2">
      <div>
        <div class="font-bold flex flex-row justify-between">
          <span>
            {{ betDescription.headline }}
          </span>
          <span>
            {{ formattedOdds }}
          </span>
        </div>
        <div class="text-xs text-gray-700">
          {{ betDescription.gameDescription }}
        </div>
        <div class="grid grid-cols-2 gap-2 mt-2">
          <InputLabel label="Wager">
            <CurrencyInput
              class="w-full"
              @focus="focusedInput = 'wager'"
              v-model="bet.wager"
            />
          </InputLabel>
          <InputLabel label="To Win">
            <CurrencyInput
              class="w-full"
              @focus="focusedInput = 'toWin'"
              v-model="bet.toWin"
            />
          </InputLabel>
        </div>
      </div>
      <div class="flex flex-col justify-around gap-2">
        <Btn @click="removeBet" size="xs" type="secondary" class="h-full"
          ><TrashIcon class="h-6 w-6"
        /></Btn>
        <Btn size="xs" type="primary" class="h-full"
          ><CheckCircleIcon class="h-6 w-6" @click="placeBet"
        /></Btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref, watch } from "vue";
import { BetSlipBetOverview, BSB, usePlaceBetStore } from "../../state/contest";
import CurrencyInput from "../../components/lib/inputs/CurrencyInput.vue";
import Btn from "../../components/lib/inputs/Btn.vue";
import { formatOdds, formatSpread, getToWin } from "../../utils";
import { TrashIcon } from "@heroicons/vue/outline";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import InputLabel from "../lib/layout/InputLabel.vue";
import { useAuthStore } from "../../state/auth";
import { supabase } from "../../supa";
import { notify, NotificationType } from "../notifications";
import {
  GamePotentialBet,
  PotentialBetType,
  PROC_NewBetDTO,
  PROC_NewSpreadBetDTO,
  PROC_NewMoneylineBetDTO,
  PROC_NewTotalBetDTO,
  PROC_PlaceBetDTO,
  PotentialMoneylineBet,
  PotentialSpreadBet,
  PotentialTotalBet,
} from "../../types";

const emit = defineEmits(["placedBet"]);
const props = defineProps({
  bet: {
    type: Object as PropType<BSB>,
  },
  game: {
    type: Object as PropType<GamePotentialBet>,
  },
  // betDescription: {
  //   type: Object as PropType<BetSlipBetOverview>,
  // },
  // betDetails: {
  //   type: Object as PropType<
  //     PotentialSpreadBet | PotentialMoneylineBet | PotentialTotalBet
  //   >,
  // },

  contestId: {
    type: String,
  },
});

type InputType = "wager" | "toWin";
let focusedInput = ref<InputType>(null);

function getTeamText(t: Team): string {
  if (t.abbrev) return t.abbrev;
  return t.teamName;
}

const betDescription: BetSlipBetOverview = computed(() => {
  const game = props.game;
  const betOverview = props.bet;
  // let bet: PotentialSpreadBet | PotentialMoneylineBet | PotentialTotalBet
  let odds: number;
  let headline: string = "headline";
  if (betOverview.type === PotentialBetType.HOME_SPREAD && game.spread) {
    odds = game.spread.homeOdds;
    headline = `${getTeamText(game.homeTeam)} ${formatSpread(
      game.spread.homeSpread
    )}`;
  } else if (betOverview.type === PotentialBetType.AWAY_SPREAD && game.spread) {
    odds = game.spread.awayOdds;
    headline = `${getTeamText(game.awayTeam)} ${formatSpread(
      game.spread.homeSpread * -1
    )}`;
  } else if (betOverview.type === PotentialBetType.HOME_ML && game.moneyline) {
    odds = game.moneyline.homeOdds;
    headline = `${getTeamText(game.homeTeam)} ML`;
  } else if (betOverview.type === PotentialBetType.AWAY_ML && game.moneyline) {
    odds = game.moneyline.awayOdds;
    headline = `${getTeamText(game.awayTeam)} ML`;
  } else if (betOverview.type === PotentialBetType.TOTAL_OVER && game.total) {
    odds = game.total.overOdds;
    headline = `O${game.total.total}`;
  } else if (betOverview.type === PotentialBetType.TOTAL_UNDER && game.total) {
    odds = game.total.underOdds;
    headline = `U${game.total.total}`;
  }
  const betDescription = {
    gameId: game.id,
    type: betOverview.type,
    startDate: game.startDate,
    odds,
    headline,
    gameDescription: `${getTeamText(game.homeTeam)} vs ${getTeamText(
      game.awayTeam
    )}`,
  };
  return betDescription;
});
const formattedOdds = computed(() => {
  if (!betDescription) return "--";
  return formatOdds(betDescription.value.odds);
});

watch(
  () => props.bet.wager,
  (v) => {
    if (focusedInput.value === "wager") {
      props.bet.toWin = getToWin(v, betDescription.value.odds);
    }
  }
);

watch(
  () => props.bet.toWin,
  (v) => {
    if (focusedInput.value === "toWin") {
      props.bet.wager = v / (betDescription.value.odds - 1);
    }
  }
);
const placeBetsStore = usePlaceBetStore();
function removeBet() {
  placeBetsStore.toggleBet(props.bet.gameId, props.bet.type);
}

const authStore = useAuthStore();
async function placeBet() {
  const new_bet: PROC_NewBetDTO = {
    user_id: authStore.user.id,
    wager: props.bet.wager,
    odds: betDescription.value.odds,
    contest_id: props.contestId,
  };
  let new_spreads: PROC_NewSpreadBetDTO[] = [];
  let new_moneylines: PROC_NewMoneylineBetDTO[] = [];
  let new_totals: PROC_NewTotalBetDTO[] = [];
  if (
    betDescription.value.type === PotentialBetType.HOME_SPREAD ||
    betDescription.value.type === PotentialBetType.AWAY_SPREAD
  ) {
    const betDetails: PotentialSpreadBet = props.game
      .spread as PotentialSpreadBet;
    const isOnHome = betDescription.value.type === PotentialBetType.HOME_SPREAD;
    new_spreads = [
      {
        odds: isOnHome ? betDetails.homeOdds : betDetails.awayOdds,
        // TODO: enable bet details based on type to properly create bet for stored proc
        home_spread: betDetails.homeSpread,
        game_id: props.game.id,
        team_id: isOnHome ? props.game.homeTeam.id : props.game.awayTeam.id,
        on_home: isOnHome,
      },
    ];
  }
  if (
    betDescription.value.type === PotentialBetType.HOME_ML ||
    betDescription.value.type === PotentialBetType.AWAY_ML
  ) {
    const betDetails: PotentialMoneylineBet = props.game
      .moneyline as PotentialMoneylineBet;
    const isOnHome = betDescription.value.type === PotentialBetType.HOME_ML;
    new_moneylines = [
      {
        odds: isOnHome ? betDetails.homeOdds : betDetails.awayOdds,
        // TODO: enable bet details based on type to properly create bet for stored proc
        game_id: props.game.id,
        team_id: isOnHome ? props.game.homeTeam.id : props.game.awayTeam.id,
        on_home: isOnHome,
      },
    ];
  }
  if (
    betDescription.value.type === PotentialBetType.TOTAL_OVER ||
    betDescription.value.type === PotentialBetType.TOTAL_UNDER
  ) {
    const betDetails: PotentialTotalBet = props.game.total as PotentialTotalBet;
    const isOnOver = betDescription.value.type === PotentialBetType.TOTAL_OVER;
    new_totals = [
      {
        odds: isOnOver ? betDetails.overOdds : betDetails.underOdds,
        // TODO: enable bet details based on type to properly create bet for stored proc
        game_id: props.game.id,
        total: betDetails.total,
        over: isOnOver,
      },
    ];
  }

  // TODO: place this object
  const placeBetObject: PROC_PlaceBetDTO = {
    new_bet,
    new_spreads,
    new_moneylines,
    new_totals,
  };

  console.log({ placeBetObject, game: props.game });
  // TODO: check to make sure games havent started yet
  // mostly done. figure out how to reload games so this rarely happens
  if (new Date(props.game.startDate) < new Date()) {
    notify(
      {
        type: NotificationType.ERROR,
        text: `Game already started`,
      },
      4000
    );
    return;
  }

  // TODO: check user balance before trying to place this bet
  // DONE
  const { data: contestBalance, error: contestBalanceError } = await supabase
    .from("contest_user")
    .select()
    .eq("contest_id", props.contestId)
    .eq("user_id", authStore.user.id)
    .single();
  console.log({ contestBalance, contestBalanceError });
  if (contestBalance.balance < props.bet.wager) {
    notify(
      {
        type: NotificationType.ERROR,
        text: `Contest balance is ${contestBalance.balance}`,
      },
      4000
    );
    return;
  }

  const { data, error } = await supabase.rpc("place_bet", {
    pb: placeBetObject,
  });
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Bet failed to be placed.. Please try again",
      },
      4000
    );
    return;
  }
  // console.log({ data, error });
  removeBet();
  notify(
    {
      type: NotificationType.SUCCESS,
      text: "Bet placed",
    },
    4000
  );

  emit("placedBet");
}
</script>
