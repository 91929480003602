export type LobbyContest = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  // buyIn: number;
  numParticipants: number;
};
export enum ContestStatus {
  UPCOMING,
  ACTIVE,
  PAST,
}
export type ContestOverview = {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  // buyIn: number;
  numParticipants: number;
  status: 0 | 1 | 2;
};
export type ContestParticipant = {
  id: string;
  firstName: string;
  lastName: string;
  joined: boolean;
  balance: number;
};
export type ContestDetail = {
  id: string;
  name: string;
  status: ContestStatus;
};

export type ContestUserDetail = {
  user: ContestParticipant;
};

export type Sport = {
  id: string;
  name: string;
};

export type Team = {
  id: number;
  teamName: string;
  abbrev: string;
};

export enum PotentialBetType {
  HOME_SPREAD = "HOME_SPREAD",
  AWAY_SPREAD = "AWAY_SPREAD",
  HOME_ML = "HOME_ML",
  AWAY_ML = "AWAY_ML",
  TOTAL_OVER = "TOTAL_OVER",
  TOTAL_UNDER = "TOTAL_UNDER",
}

export type PotentialSpreadBet = {
  id: string;
  homeOdds: number;
  awayOdds: number;
  homeSpread: number;
  bookId: number;
};

export type PotentialMoneylineBet = {
  id: string;
  homeOdds: number;
  awayOdds: number;
  bookId: number;
};

export type PotentialTotalBet = {
  id: string;
  overOdds: number;
  underOdds: number;
  total: number;
  bookId: number;
};

export type GamePotentialBet = {
  id: string;
  startDate: string;
  homeTeam: Team;
  awayTeam: Team;
  sport: Sport;
  moneyline?: PotentialMoneylineBet;
  spread?: PotentialSpreadBet;
  total?: PotentialTotalBet;
};

// FOR STORED PROC ------------------
export type PROC_NewSpreadBetDTO = {
  odds: number;
  home_spread: number;
  team_id: number;
  game_id: string;
  on_home: boolean;
};
export type PROC_NewMoneylineBetDTO = {
  odds: number;
  team_id: number;
  game_id: string;
  on_home: boolean;
};
export type PROC_NewTotalBetDTO = {
  odds: number;
  game_id: string;
  total: number;
  over: boolean;
};
export type PROC_NewBetDTO = {
  user_id: string;
  wager: number;
  odds: number;
  contest_id: string;
};
export type PROC_PlaceBetDTO = {
  new_bet: PROC_NewBetDTO;
  new_spreads: PROC_NewSpreadBetDTO[];
  new_moneylines: PROC_NewMoneylineBetDTO[];
  new_totals: PROC_NewTotalBetDTO[];
};
// -- drop type if exists new_spread_bet_dto;
// -- create type new_spread_bet_dto as (odds float, home_spread float, team_id bigint, game_id uuid, on_home boolean);

// -- drop type if exists new_moneyline_bet_dto;
// -- create type new_moneyline_bet_dto as (odds float, team_id bigint, game_id uuid, on_home boolean);

// -- drop type if exists new_total_bet_dto;
// -- create type new_total_bet_dto as (odds float, total float, game_id uuid, over boolean);

// -- drop type if exists place_bet_dto;
// -- create type place_bet_dto as (new_bet new_bet_dto, new_spreads new_spread_bet_dto[], new_moneylines new_moneyline_bet_dto[], new_totals new_total_bet_dto[]);
// -- -- outcome
// -- drop type if exists new_bet_dto;
// -- create type new_bet_dto as (user_id uuid, wager float, odds float, contest_id uuid);

export type Game = {
  id: string;
  homeTeam: Team;
  awayTeam: Team;
  startDate: string;
  homeScore?: number;
  awayScore?: number;
  isComplete: boolean;
};
export type MoneylineBet = {
  id: string;
  game: Game;
  odds: number;
  outcome: number;
  teamId: string;
  onHome: boolean;
};
export type SpreadBet = {
  id: string;
  game: Game;
  odds: number;
  outcome: number;
  teamId: string;
  onHome: boolean;
  homeSpread: number;
};
export type TotalBet = {
  id: string;
  game: Game;
  odds: number;
  outcome: number;
  over: boolean;
  total: number;
};

export type PlacedBet = {
  id: string;
  userId: string;
  contestId: string;
  outcome: number;
  wager: number;
  odds: number;
  placedAt: string;
  moneyline: MoneylineBet[];
  spread: SpreadBet[];
  total: TotalBet[];
};
