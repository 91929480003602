import { supabase } from "../supa";

export function getPlacedBets(contestId?: string, userId?: string) {
  let query = supabase.from("bet").select(
    `
        id, userId:user_id, contestId:contest_id, outcome, wager, odds, placedAt:placed_at
        , moneyline:moneyline_bet (id, outcome,  odds, teamId:team_id, onHome:on_home,
          game (
            id, startDate:start_date, homeScore:home_score, awayScore:away_score, isComplete:is_complete
            , homeTeam:home_team_id (id, abbrev, teamName:team_name)
            , awayTeam:away_team_id (id, abbrev, teamName:team_name)
          )
        )
        , spread:spread_bet (id, outcome,  odds, teamId:team_id, onHome:on_home, homeSpread:home_spread,
          game (
            id, startDate:start_date, homeScore:home_score, awayScore:away_score, isComplete:is_complete
            , homeTeam:home_team_id (id, abbrev, teamName:team_name)
            , awayTeam:away_team_id (id, abbrev, teamName:team_name)
          )
        )
        , total:total_bet (id, outcome,  odds, over, total,
          game (
            id, startDate:start_date, homeScore:home_score, awayScore:away_score, isComplete:is_complete
            , homeTeam:home_team_id (id, abbrev, teamName:team_name)
            , awayTeam:away_team_id (id, abbrev, teamName:team_name)
          )
        ),
        contest (id, name)

      `
  );
  if (userId) {
    query.eq("user_id", userId);
  }
  if (contestId) {
    query.eq("contest_id", contestId);
  }

  return query;
}
