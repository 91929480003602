<template>
  <template v-if="ptsValue || oddsValue">
    <div :class="betClassess">
      <span>
        {{ formattedMain }}
      </span>
      <span :class="betOddsClasses">
        {{ formattedSub }}
      </span>
    </div>
  </template>
  <template v-else>
    <div :class="[betClassess, invalidBetClasses]">--</div>
  </template>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { PotentialBetType } from "../../types";
import { formatOdds, formatSpread } from "../../utils";

const props = defineProps({
  type: {
    type: String as PropType<PotentialBetType>,
    required: true,
  },
  ptsValue: {
    type: Number,
  },
  oddsValue: {
    type: Number,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const formattedMain = computed(() => {
  if (props.type === PotentialBetType.HOME_SPREAD) {
    return formatSpread(props.ptsValue);
  } else if (props.type === PotentialBetType.AWAY_SPREAD) {
    return formatSpread(props.ptsValue * -1);
  } else if (
    [PotentialBetType.HOME_ML, PotentialBetType.AWAY_ML].includes(props.type)
  ) {
    return null;
  } else if (props.type === PotentialBetType.TOTAL_OVER) {
    return `O${props.ptsValue}`;
  } else if (props.type === PotentialBetType.TOTAL_UNDER) {
    return `U${props.ptsValue}`;
  } else return null;
});
const formattedSub = computed(() => {
  if (props.oddsValue) {
    return formatOdds(props.oddsValue);
  }
  return null;
});

const betClassess = computed(() => {
  return (
    "flex flex-col justify-center rounded-2xl border h-11 hover:cursor-pointer " +
    (props.active ? "bg-primary-200" : "bg-white md:hover:bg-primary-50")
  );
});
const invalidBetClasses = "bg-gray-50 hover:bg-gray-50 hover:cursor-default ";
const betOddsClasses = "text-primary-700";
</script>
