<template>
  <div class="h-full">
    <template v-if="contestLoading"> <Loading /> </template>
    <template v-else>
      <div class="px-2 py-4 flex flex-col gap-4">
        <Btn
          type="text"
          size="xs"
          @click="$router.go(-1)"
          class="mb-4 self-start"
        >
          <template v-if="joined"> Back to My Contests </template>
          <template v-else> Back to Lobby </template>
        </Btn>
        <div class="rounded-2xl p-4 bg-white border">
          <div class="flex flex-row justify-between mb-2">
            <div class="text-xl font-semibold">
              {{ contest.name }}
            </div>
            <div class="text-base font-medium">
              {{ status }}
            </div>
          </div>
          <!-- <template v-if="joined">
            <div class="flex flex-col">
              <div class="text-base font-medium">
                {{ contest.balance }}
              </div>
              <div class="text-sm font-medium text-gray-600 uppercase">
                balance
              </div>
            </div>
          </template> -->
          <template v-if="userPerformance">
            <div class="grid grid-cols-4">
              <div class="flex flex-col items-center">
                <div class="text-sm font-medium">
                  <template v-if="userPerformance.numTotal > 0">
                    {{ userPerformance.profit > 0 ? "+" : ""
                    }}{{ userPerformance.profitPct.toFixed(1) }}%
                  </template>
                  <template v-else> -- </template>
                </div>
                <div class="text-sm text-gray-500">Profit</div>
              </div>
              <div class="flex flex-col items-center">
                <div class="text-sm font-medium">
                  {{ userPerformance.numTotal }}
                </div>
                <div class="text-sm text-gray-500">Bets</div>
              </div>
              <div class="flex flex-col items-center">
                <div class="text-sm font-medium">
                  <template
                    v-if="
                      userPerformance.numTotal - userPerformance.numAlive > 0
                    "
                  >
                    {{ userPerformance.winPct.toFixed(1) }}%
                  </template>
                  <template v-else> -- </template>
                </div>
                <div class="text-sm text-gray-500">Win %</div>
              </div>
              <div class="flex flex-col items-center">
                <div class="text-sm font-medium">
                  <template v-if="userPerformance.numTotal > 0">
                    ${{ userPerformance.totalAlive }}
                  </template>
                  <template v-else> -- </template>
                </div>
                <div class="text-sm text-gray-500">Alive</div>
              </div>
            </div>
          </template>
        </div>
        <div class="flex flex-col gap-2">
          <!-- && isActiveContest -->
          <template v-if="joined && !isPastContest">
            <router-link
              :to="getURL(`/contests/${contest.id}/placeBets`)"
              custom
              v-slot="{ navigate }"
            >
              <Btn class="w-full" @click="navigate"> Place Bets </Btn>
            </router-link>
          </template>
          <!-- <template v-else-if="joined && isPastContest">
            <div>past contest</div>
          </template> -->
          <template v-if="!joined && !isPastContest">
            <Btn class="w-full" @click="joinContest"> Join Contest </Btn>
          </template>

          <router-link
            :to="
              joined
                ? getURL(`/contests/${contest.id}/leaderboard`)
                : getURL(`/lobby/${contest.id}/leaderboard`)
            "
            custom
            v-slot="{ navigate }"
          >
            <Btn class="w-full" @click="navigate" type="secondary">
              <template v-if="joined"> Leaderboard </template>
              <template v-else> userPerformance List </template>
            </Btn>
          </router-link>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ContestDetail, ContestOverview, ContestStatus } from "../../types";
import Btn from "../../components/lib/inputs/Btn.vue";
import Loading from "../../components/lib/inputs/Loading.vue";
import { getURL, getStatus } from "../../utils";
import { supabase } from "../../supa";
import { NotificationType, notify } from "../../components/notifications";
import { useAuthStore } from "../../state/auth";
import { getUserPerformance } from "../../query/getContestUserPerformance";
const route = useRoute();

type ThisPageContest = ContestOverview & { balance: number | null };
let contest = ref<ThisPageContest>(null);
let contestLoading = ref<boolean>(true);

const props = defineProps({
  initialJoined: {
    type: Boolean,
    default: false,
  },
});

const joined = ref(props.initialJoined);

watch(
  () => props.initialJoined,
  (j: boolean) => {
    joined.value = j;
  }
);

const contestId = route.params.contestId as string;
async function getcontest(contestId: string) {
  const { data, error } = await supabase
    .from("contest")
    .select(
      `id, name, starts, ends, is_public
      ,user:contest_user (balance, is_admin, is_bought_in, joined)
      `
    )
    .eq("id", contestId)
    .eq("user.user_id", authStore.user.id);
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "No contest with this id",
      },
      4000
    );
    return;
  }

  if (data.length === 0) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    return;
  }
  const c = data[0];

  const contestOverview: ThisPageContest = {
    id: c.id,
    name: c.name,
    startDate: c.starts,
    endDate: c.ends,
    // buyIn: null,
    numParticipants: null,
    status: getStatus(c.starts, c.ends),
    balance: c.user && c.user.length ? c.user[0].balance : null,
  };
  contest.value = contestOverview;
  contestLoading.value = false;
}

type ContestPerformance = {
  balance: number;
  contestId: string;
  firstName: string;
  isAdmin: false;
  isBoughtIn: true;
  joined: true;
  lastName: string;
  numAlive: number;
  numTotal: number;
  numWon: number;
  profit: number;
  profitPct: number;
  roi: number;
  totalAlive: number;
  userId: string;
  winPct: number;
};

const userPerformance = ref<ContestPerformance | null>(null);
async function getUserPerformanceData(contestId, userId) {
  const { data, error } = await getUserPerformance(contestId, userId).single();
  console.log("contest performance", { contestId, userId, data, error });
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Couldnt fetch user performance",
      },
      4000
    );
    return;
  }
  userPerformance.value = data;
}

onMounted(() => {
  getcontest(contestId);
  if (props.initialJoined) {
    getUserPerformanceData(contestId, authStore.user.id);
  }
});

const isActiveContest = computed(() => {
  return contest.value.status === ContestStatus.ACTIVE;
});
const isPastContest = computed(
  () => contest.value.status === ContestStatus.PAST
);

const status = computed(() => {
  if (contest.value.status === ContestStatus.ACTIVE)
    return `$${contest.value.balance}`;
  if (contest.value.status === ContestStatus.UPCOMING) return "Upcoming";
  if (contest.value.status === ContestStatus.PAST)
    return `$${contest.value.balance}`;
});
const authStore = useAuthStore();
const router = useRouter();
async function joinContest() {
  const { data, error } = await supabase.from("contest_user").insert({
    contest_id: contestId,
    user_id: authStore.user.id,
    joined: true,
  });
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Could not join contest!",
      },
      4000
    );
    return;
  }
  console.log({ data });
  joined.value = true;
  // PHIL TODO: if user is in lobby, check to see if they have joined. if they have joined, send them to contests detail out of lobby
  // contest.value = data
  navToDetail(contestId);
}

function navToDetail(contestId: string) {
  router.replace(getURL(`/contests/${contestId}`));
}
// const outcomeStyles = computed(() => {
//   if (props.contest.status === null) return null;
//   //   if (props.bet.outcome === null) return "bg-primary-200 text-primary-700";
//   if (props.contest.status === 0) return "bg-red-400 text-white";
//   if (props.contest.status === 1) return "bg-green-600 text-white";
// });
</script>
