<template>
  <div class="h-full">
    <template v-if="loadingContests"> <Loading /> </template>
    <template v-else>
      <Tabs v-model="type" :options="contestTypes" />
      <ContestList :contests="visibleContests" :type="type" />
    </template>
  </div>
</template>
<script setup lang="ts">
import ContestList from "../../components/contests/ContestList.vue";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { ContestOverview, ContestStatus } from "../../types";
import { useRoute, useRouter } from "vue-router";
import Loading from "../../components/lib/inputs/Loading.vue";
import Tabs from "../../components/lib/inputs/Tabs.vue";
import { supabase } from "../../supa";
import { useAuthStore } from "../../state/auth";
import { NotificationType, notify } from "../../components/notifications";
const contestTypes = [
  {
    value: ContestStatus.UPCOMING,
    label: "Upcoming",
  },
  {
    value: ContestStatus.ACTIVE,
    label: "Active",
  },
  {
    value: ContestStatus.PAST,
    label: "Past",
  },
];

const type = ref<ContestStatus>(ContestStatus.ACTIVE);
const title = computed(() => {
  if (type.value === ContestStatus.UPCOMING) return "Upcoming";
  if (type.value === ContestStatus.ACTIVE) return "Active";
  if (type.value === ContestStatus.PAST) return "Past";
});

let contests = ref<ContestOverview[]>([]);

const loadingContests = ref(true);
const authStore = useAuthStore();
async function fetchContests() {
  const { data, error } = await supabase.rpc("get_users_contests", {
    uid: authStore.user.id,
  });
  // const {data, error} = await supabase.from("contest").select()

  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    return;
  }

  const contestOverviews: ContestOverview[] = [];
  for (let i = 0; i < data.length; i++) {
    const c = data[i];
    contestOverviews.push({
      id: c.id,
      name: c.name,
      startDate: c.starts,
      endDate: c.ends,
      numParticipants: c.num_users,
      status: c.status,
    });
  }

  contests.value = contestOverviews;
  loadingContests.value = false;
}
fetchContests();

const visibleContests = computed(() => {
  return contests.value.filter((c) => c.status === type.value);
});
const route = useRoute();
onMounted(() => {
  const type = parseInt(route.query.t as string);
  if ([1, 2, 3].includes(type)) {
    handleContestTypeChange(type);
  }
});
const router = useRouter();
function handleContestTypeChange(t) {
  router.replace({ path: "", query: { t: t } });
  type.value = t;
}

watch(type, (v) => {
  handleContestTypeChange(v);
});
// return type
</script>
