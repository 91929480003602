<template>
  <div class="h-full">
    <template v-if="loadingBets"> <Loading /> </template>
    <template v-else>
      <div class="text-3xl font-bold px-2 py-2 stickyy top-0">My Bets</div>
      <div class="flex flex-col px-2 py-2 gap-2 pb-16">
        <template v-if="placedBets && placedBets.length">
          <template v-for="bet in placedBets" :key="bet.id">
            <PlacedBetComponent :bet="bet" :contestName="bet.contest.name" />
          </template>
        </template>
        <template v-else>
          <div>No bets have been placed</div>
        </template>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import Loading from "../components/lib/inputs/Loading.vue";
import PlacedBetComponent from "../components/contests/PlacedBet.vue";
import { useAuthStore } from "../state/auth";
import { PlacedBet } from "../types";
import { supabase } from "../supa";
import { NotificationType, notify } from "../components/notifications";
import { getPlacedBets } from "../query/getPlacedBets";

const loadingBets = ref(true);
let placedBets = ref<(PlacedBet & { contest: { id: string; name: string } })[]>(
  []
);
const authStore = useAuthStore();
async function getUserBets() {
  loadingBets.value = true;

  const { data, error } = await getPlacedBets(null, authStore.user.id);
  // console.log({ data });
  // console.log("Get placed bets: ", { data, error });
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    loadingBets.value = false;
    return;
  }
  placedBets.value = data.sort((a, b) => {
    if (a.outcome === null) {
      return -1;
    }
    if (b.outcome === null) {
      return 1;
    }
    if (new Date(b.placedAt) > new Date(a.placedAt)) {
      return 1;
    } else {
      return -1;
    }
  });
  loadingBets.value = false;
}

onMounted(() => {
  getUserBets();
});
</script>
