import { events } from "./events";

let count = 0;

const generateId = () => {
  return count++;
};

export enum NotificationType {
  SUCCESS,
  ERROR,
}
export enum NotificationLocation {
  bottom = "bottom",
}
export type Notification = {
  id?: number;
  type: NotificationType;
  group?: NotificationLocation;
  // title: string;
  text: string;
};
export const notify = (notification: Notification, timeout: number = 3000) => {
  notification.id = generateId();
  notification.group = notification.group || NotificationLocation.bottom;
  events.emit("notify", { notification, timeout });
};
