<template>
  <template v-if="store.loading">
    <Loading :wholePage="true" />
  </template>
  <div class="min-h-screen h-screen">
    <!-- <div class="pb-12 h-full bg-primary-50"> -->
    <router-view />
  </div>
  <NotificationGroup group="bottom" position="bottom">
    <div
      class="fixed inset-x-0 bottom-0 flex items-start justify-end p-6 px-4 pb-16 pointer-events-none gap-2"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <template
            v-for="notification in notifications"
            :key="notification.id"
          >
            <div
              class="flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg mt-2"
            >
              <template v-if="notification.type === NotificationType.ERROR">
                <div class="flex items-center justify-center w-12 bg-red-500">
                  <ExclamationCircleIcon class="w-6 h-6 text-white" />
                </div>
              </template>
              <template v-else>
                <div class="flex items-center justify-center w-12 bg-green-600">
                  <CheckCircleIcon class="w-6 h-6 text-white" />
                </div>
              </template>

              <div class="px-4 py-2 -mx-3">
                <div class="mx-3">
                  <!-- <span class="font-semibold text-red-500">{{
                    notification.title
                  }}</span> -->
                  <p class="text-base text-gray-600">{{ notification.text }}</p>
                </div>
              </div>
            </div>
          </template>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
</template>

<script setup lang="ts">
import Notification from "./components/notifications/Notification.vue";
import NotificationGroup from "./components/notifications/NotificationGroup.vue";
import { NotificationType, notify } from "./components/notifications";
import { useAuthStore } from "./state/auth";
import { supabase } from "./supa";
import Loading from "./components/lib/inputs/Loading.vue";
import { useRouter } from "vue-router";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/vue/solid";
const store = useAuthStore();

// if hash has access_token, its redirected from google
if (window.location.hash.includes("access_token")) {
  store.loading = true;
}

const router = useRouter();

store.user = supabase.auth.user();
// if user already defined on the client, check for profile, set loading false
if (store.user) {
  store.loading = true;
  store.getCurrentProfile(store.user.id).then((profile) => {
    if (profile) {
      store.profile = profile;
    }
    store.loading = false;
  });
}
// when auth state changes, update store.user and store.profile and loading=false
supabase.auth.onAuthStateChange(async (event, session) => {
  if (session) {
    store.user = session.user;
    let profile = await store.getCurrentProfile(store.user.id);
    if (!profile.isComplete && !profile.email) {
      const { data, error } = await supabase
        .from("user_profile")
        .update({ email: session.user.email })
        .match({ id: store.user.id });
    }
    // PHILTODO: check for user profile update error
    profile = await store.getCurrentProfile(store.user.id);
    store.profile = profile;

    // await fetch("/api/auth", {
    //   method: "POST",
    //   headers: new Headers({ "Content-Type": "application/json" }),
    //   credentials: "same-origin",
    //   body: JSON.stringify({ event, session }),
    // }).then((res) => res.json());
  }
  // user has logged out, nav to login
  else {
    store.user = null;
    store.profile = null;
    // await fetch("/api/auth", {
    //   method: "POST",
    //   headers: new Headers({ "Content-Type": "application/json" }),
    //   credentials: "same-origin",
    //   body: JSON.stringify({ event, session }),
    // }).then((res) => res.json());
    router.push("/login");
  }
  store.loading = false;
});
</script>
