import { defineStore } from "pinia";
import { GamePotentialBet, PlacedBet, PotentialBetType } from "../types";

export type BSB = {
  wager: number;
  toWin: number;
  gameId: string;
  type: PotentialBetType;
};

export type BetSlipBetOverview = {
  gameId: string;
  type: PotentialBetType;
  headline: string;
  odds: number;
  gameDescription: string;
  startDate: string;
};

export type BSBMap = {
  // game id & bet type
  // `${gameId}_${betType}`
  [key: string]: BSB;
};

// export type PlacedBet = {
//   gameId: string;
//   type: PotentialBetType;
//   headline: string;
//   odds: number;
//   gameDescription: string;
//   startDate: string;

//   wager: number;
//   started: 0 | 1;
//   outcome: 0 | 1 | null;
// };

export const usePlaceBetStore = defineStore("placeBets", {
  state: (): { betSlip: BSBMap; placedBets: PlacedBet[] } => {
    return {
      betSlip: {},
      placedBets: [],
    };
  },
  actions: {
    checkValidity(games: GamePotentialBet[]) {
      const gameKeys = games.flatMap((g) => {
        const keys = [];
        if (g.spread) {
          keys.push(`${g.id}_${PotentialBetType.HOME_SPREAD}`);
          keys.push(`${g.id}_${PotentialBetType.AWAY_SPREAD}`);
        }
        if (g.moneyline) {
          keys.push(`${g.id}_${PotentialBetType.HOME_ML}`);
          keys.push(`${g.id}_${PotentialBetType.AWAY_ML}`);
        }
        if (g.total) {
          keys.push(`${g.id}_${PotentialBetType.TOTAL_OVER}`);
          keys.push(`${g.id}_${PotentialBetType.TOTAL_UNDER}`);
        }
        return keys;
      });
      const newMap = {};
      const currKeys = Object.keys(this.betSlip);
      // for each betOverview in bet slip
      for (let i = 0; i < currKeys.length; i++) {
        const ck = currKeys[i];
        // if bet is still available for contest, leave in betSlip. otherwise remove
        if (gameKeys.includes(ck)) {
          newMap[ck] = this.betSlip[ck];
        }
      }
      this.betSlip = newMap;
    },
    toggleBet(gameId: string, type: PotentialBetType) {
      const betStr = `${gameId}_${type}`;
      if (this.betSlip[betStr]) {
        delete this.betSlip[betStr];
      } else {
        const bsb: BSB = {
          wager: 0,
          toWin: 0,
          gameId,
          type,
        };
        this.betSlip[betStr] = bsb;
      }
    },
  },
});
