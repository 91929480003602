<template>
  <div class="h-full">
    <template v-if="loading"> <Loading /> </template>
    <template v-else>
      <div class="p-2">
        <Btn type="text" size="xs" @click="$router.go(-1)" class="mb-4">
          Back to Contest Detail
        </Btn>
        <div class="flex flex-col gap-2">
          <template v-if="participants && participants.length">
            <template v-for="participant in participants" :key="participant.id">
              <router-link
                :to="getURL(`/contests/${contestId}/${participant.userId}`)"
                class="px-4 py-2 rounded-2xl bg-white shadow hover:bg-primary-100"
              >
                <div class="flex flex-row justify-between mb-2">
                  <div class="text-lg font-medium">
                    {{ participant.firstName }}
                    {{ participant.lastName }}
                  </div>
                  <div
                    v-if="!participant.joined"
                    class="bg-primary-100 text-primary-600 text-sm px-4 py-1 rounded-full flex items-center justify-center"
                  >
                    Invited
                  </div>
                  <div v-else class="text-lg font-medium">
                    ${{ participant.balance }}
                  </div>
                </div>
                <div class="grid grid-cols-4">
                  <div class="flex flex-col items-center">
                    <div class="text-sm font-medium">
                      <template v-if="participant.numTotal > 0">
                        {{ participant.profit > 0 ? "+" : ""
                        }}{{ participant.profitPct.toFixed(1) }}%
                      </template>
                      <template v-else> -- </template>
                    </div>
                    <div class="text-sm text-gray-500">Profit</div>
                  </div>
                  <div class="flex flex-col items-center">
                    <div class="text-sm font-medium">
                      {{ participant.numTotal }}
                    </div>
                    <div class="text-sm text-gray-500">Bets</div>
                  </div>
                  <div class="flex flex-col items-center">
                    <div class="text-sm font-medium">
                      <template
                        v-if="participant.numTotal - participant.numAlive > 0"
                      >
                        {{ participant.winPct.toFixed(1) }}%
                      </template>
                      <template v-else> -- </template>
                    </div>
                    <div class="text-sm text-gray-500">Win %</div>
                  </div>
                  <div class="flex flex-col items-center">
                    <div class="text-sm font-medium">
                      <template v-if="participant.numTotal > 0">
                        ${{ participant.totalAlive }}
                      </template>
                      <template v-else> -- </template>
                    </div>
                    <div class="text-sm text-gray-500">Alive</div>
                  </div>
                </div>
              </router-link>
            </template>
          </template>
          <template v-else>
            <div>No participants</div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { ContestParticipant } from "../../types";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import Btn from "../../components/lib/inputs/Btn.vue";
import Loading from "../../components/lib/inputs/Loading.vue";
import { supabase } from "../../supa";
import { NotificationType, notify } from "../../components/notifications";
import { getURL } from "../../utils";
import { getUserPerformance } from "../../query/getContestUserPerformance";
const route = useRoute();
let participants = ref<ContestParticipant[]>(null);
let loading = ref<boolean>(true);

async function getLeaderboard(contestId: string) {
  // const { data, error } = await supabase
  //   .from("contest_user")
  //   .select(
  //     `balance, is_admin, is_bought_in, joined, user:user_id (id, first_name, last_name)`
  //   )
  //   .filter("contest_id", "eq", contestId);

  const { data, error } = await getUserPerformance(contestId);

  // console.log({ data, error });

  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong!",
      },
      4000
    );
    return;
  }

  // const contestParticipants: ContestParticipant[] = [];
  // for (let i = 0; i < data.length; i++) {
  //   const c = data[i];
  //   contestParticipants.push({
  //     id: c.user.id,
  //     firstName: c.user.first_name,
  //     lastName: c.user.last_name,
  //     balance: c.balance,
  //     joined: c.joined,
  //   });
  // }
  // participants.value = contestParticipants.sort((a, b) => {
  //   return b.balance - a.balance;
  // });
  participants.value = data.sort((a, b) => {
    if (a.numTotal === 0) return 1;
    if (b.numTotal === 0) return -1;
    return b.profit - a.profit;
  });
  loading.value = false;
}

let contestId = ref<string>(undefined);

onMounted(() => {
  contestId.value = route.params.contestId as string;
  getLeaderboard(contestId.value);
});
</script>
