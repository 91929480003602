<template>
  <div class="h-full">
    <template v-if="loadingContests"> <Loading /> </template>
    <template v-else>
      <div class="text-3xl font-bold px-2 py-2 stickyy top-0">Lobby</div>
      <div class="flex flex-col px-2 py-2 gap-2 pb-16">
        <template v-if="contests && contests.length">
          <template v-for="contest in contests" :key="contest.id">
            <router-link
              :to="getURL(`/lobby/${contest.id}`)"
              v-slot="{ navigate }"
              custom
            >
              <ContestOverview :contest="contest" @click="navigate" />
            </router-link>
          </template>
        </template>
        <template v-else>
          <div>No contests in lobby</div>
        </template>
      </div>
    </template>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import ContestOverview from "../../components/lobby/ContestOverview.vue";
import { LobbyContest } from "../../types/index";
import Loading from "../../components/lib/inputs/Loading.vue";
import { getURL } from "../../utils";
import { supabase } from "../../supa";
import { useAuthStore } from "../../state/auth";
import { notify, NotificationType } from "../../components/notifications";
let contests = ref<LobbyContest[]>([]);

const loadingContests = ref(true);

const authStore = useAuthStore();
async function fetchContests() {
  const { data, error } = await supabase.rpc("get_lobby_contests", {
    uid: authStore.user.id,
  });
  if (error) {
    notify(
      {
        type: NotificationType.ERROR,
        text: "Something went wrong",
      },
      4000
    );
    return;
  }
  console.log({ data });

  const lobbyContests: LobbyContest[] = [];
  for (let i = 0; i < data.length; i++) {
    const c = data[i];
    lobbyContests.push({
      id: c.id,
      name: c.name,
      startDate: c.starts,
      endDate: c.ends,
      // buyIn: c.buyIn,
      numParticipants: c.num_users,
    });
  }

  loadingContests.value = false;
  contests.value = lobbyContests;
}
onMounted(() => {
  fetchContests();
});
</script>
