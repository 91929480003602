<template>
  <div class="flex flex-col gap-2 px-10 py-20">
    <div class="flex gap-4 items-center">
      <Btn size="xs">Button Text</Btn>
      <Btn size="sm">Button Text</Btn>
      <Btn size="md">Button Text</Btn>
      <Btn size="lg">Button Text</Btn>
      <Btn size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn disabled size="xs">Button Text</Btn>
      <Btn disabled size="sm">Button Text</Btn>
      <Btn disabled size="md">Button Text</Btn>
      <Btn disabled size="lg">Button Text</Btn>
      <Btn disabled size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn loading size="xs">Button Text</Btn>
      <Btn loading size="sm">Button Text</Btn>
      <Btn loading size="md">Button Text</Btn>
      <Btn loading size="lg">Button Text</Btn>
      <Btn loading size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn disabled loading size="xs">Button Text</Btn>
      <Btn disabled loading size="sm">Button Text</Btn>
      <Btn disabled loading size="md">Button Text</Btn>
      <Btn disabled loading size="lg">Button Text</Btn>
      <Btn disabled loading size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn type="secondary" size="xs">Button Text</Btn>
      <Btn type="secondary" size="sm">Button Text</Btn>
      <Btn type="secondary" size="md">Button Text</Btn>
      <Btn type="secondary" size="lg">Button Text</Btn>
      <Btn type="secondary" size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn type="secondary" disabled size="xs">Button Text</Btn>
      <Btn type="secondary" disabled size="sm">Button Text</Btn>
      <Btn type="secondary" disabled size="md">Button Text</Btn>
      <Btn type="secondary" disabled size="lg">Button Text</Btn>
      <Btn type="secondary" disabled size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn type="secondary" loading size="xs">Button Text</Btn>
      <Btn type="secondary" loading size="sm">Button Text</Btn>
      <Btn type="secondary" loading size="md">Button Text</Btn>
      <Btn type="secondary" loading size="lg">Button Text</Btn>
      <Btn type="secondary" loading size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn disabled type="secondary" loading size="xs">Button Text</Btn>
      <Btn disabled type="secondary" loading size="sm">Button Text</Btn>
      <Btn disabled type="secondary" loading size="md">Button Text</Btn>
      <Btn disabled type="secondary" loading size="lg">Button Text</Btn>
      <Btn disabled type="secondary" loading size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn type="text" size="xs">Button Text</Btn>
      <Btn type="text" size="sm">Button Text</Btn>
      <Btn type="text" size="md">Button Text</Btn>
      <Btn type="text" size="lg">Button Text</Btn>
      <Btn type="text" size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn type="text" disabled size="xs">Button Text</Btn>
      <Btn type="text" disabled size="sm">Button Text</Btn>
      <Btn type="text" disabled size="md">Button Text</Btn>
      <Btn type="text" disabled size="lg">Button Text</Btn>
      <Btn type="text" disabled size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn type="text" loading size="xs">Button Text</Btn>
      <Btn type="text" loading size="sm">Button Text</Btn>
      <Btn type="text" loading size="md">Button Text</Btn>
      <Btn type="text" loading size="lg">Button Text</Btn>
      <Btn type="text" loading size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center">
      <Btn disabled type="text" loading size="xs">Button Text</Btn>
      <Btn disabled type="text" loading size="sm">Button Text</Btn>
      <Btn disabled type="text" loading size="md">Button Text</Btn>
      <Btn disabled type="text" loading size="lg">Button Text</Btn>
      <Btn disabled type="text" loading size="xl">Button Text</Btn>
    </div>
    <div class="flex gap-4 items-center w-[375px]">
      <Tabs v-model="selected" :options="options" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Btn from "../components/lib/inputs/Btn.vue";
import Tabs from "../components/lib/inputs/Tabs.vue";

const selected = ref<number>(0);
const options = [
  {
    value: 0,
    label: "Upcoming",
  },
  {
    value: 1,
    label: "Active",
  },
  {
    value: 3,
    label: "Past",
  },
];
</script>
