<template>
  <button
    class="inline-flex items-center justify-center border border-transparent font-medium"
    :class="[typeClasses, sizeClasses]"
    :disabled="disabled"
  >
    <template v-if="loading">
      <div>
        <div
          :class="[loadingTypeClasses, loadingSizeClasses]"
          style="border-top-color: transparent"
          class="border-solid rounded-full animate-spin"
        ></div>
      </div>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";

const props = defineProps({
  type: {
    type: String as PropType<"primary" | "secondary" | "text">,
    default: "primary",
  },
  size: {
    type: String as PropType<"xs" | "sm" | "md" | "lg" | "xl">,
    default: "sm",
  },
  disabled: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
});
const typeClasses = computed(() => {
  if (props.type === "primary")
    return "text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 shadow-sm disabled:bg-primary-800 disabled:text-primary-50";
  if (props.type === "secondary")
    return "text-primary-700 bg-primary-100 hover:bg-primary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 shadow-sm disabled:bg-primary-200 disabled:text-primary-900";
  if (props.type === "text")
    return "text-primary-700 disabled:text-primary-900";
});
const sizeClasses = computed(() => {
  if (props.size === "xs")
    return [
      props.type !== "text" ? "px-2.5 py-1.5" : "",
      "text-xs rounded-xl",
    ].join(" ");
  if (props.size === "sm")
    return [
      props.type !== "text" ? "px-3 py-2" : "",
      "text-sm rounded-xl  leading-4",
    ].join(" ");
  if (props.size === "md")
    return [
      props.type !== "text" ? "px-4 py-2" : "",
      "text-sm rounded-xl",
    ].join(" ");
  if (props.size === "lg")
    return [
      props.type !== "text" ? "px-4 py-2" : "",
      "text-base rounded-2xl",
    ].join(" ");
  if (props.size === "xl")
    return [
      props.type !== "text" ? "px-6 py-3" : "",
      "text-base rounded-2xl",
    ].join(" ");
});
const loadingTypeClasses = computed(() => {
  if (props.type === "primary") return "border-white";
  if (props.type === "secondary")
    return "border-primary-700 disabled:border-bg-200 disabled:border-primary-900";
  if (props.type === "text")
    return "border-primary-700 disabled:border-primary-900";
});
const loadingSizeClasses = computed(() => {
  if (props.size === "xs") return ["w-4 h-4 border-4"].join(" ");
  if (props.size === "sm") return ["w-4 h-4 border-4"].join(" ");
  if (props.size === "md") return ["w-5 h-5 border-4"].join(" ");
  if (props.size === "lg") return ["w-6 h-6 border-4"].join(" ");
  if (props.size === "xl") return ["w-6 h-6 border-4"].join(" ");
});
</script>
