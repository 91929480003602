<template>
  <div class="h-full flex flex-col items-center justify-center px-10">
    <Btn @click="signInWithGoogle" class="w-full">Login</Btn>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import Btn from "../components/lib/inputs/Btn.vue";
import { supabase } from "../supa";

async function signInWithGoogle() {
  const { user, session, error } = await supabase.auth.signIn(
    {
      provider: "google",
    },
    {
      redirectTo: window.location.href,
      scopes:
        "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile ",
    }
  );
}
</script>
